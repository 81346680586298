import { Link as DefaultLink } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { space, rem, fontSize } from "../design-system";
import MainContainer from "./MainContainer";
import Button from "./Button";
import Link from "./Link";
import logo from "../images/burch-rentals-logo.png";

const Root = styled.header``;
const Container = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${rem(space(400))};
  padding-bottom: ${rem(space(400))};

  @media screen and (min-width: 600px) {
    padding-top: ${rem(space(600))};
    padding-bottom: ${rem(space(600))};
  }
`;
const TitleLink = styled(DefaultLink)`
  display: inline-block;
  width: 100px;
  height: 55px;
  color: white;
  text-decoration: none;
  flex-shrink: 0;
  background-image: url(${logo});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media screen and (min-width: 600px) {
    width: 133px;
    height: 73px;
  }
`;
const Menu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;
const MenuItem = styled.li`
  font-size: ${rem(fontSize(200))};

  &:not(:first-child) {
    padding-left: ${rem(space(600))};
  }
`;
const SecondaryMenuItem = styled(MenuItem)`
  display: none;

  @media screen and (min-width: 520px) {
    display: inline-block;
  }
`;
const MenuLink = styled(Link)`
  font-weight: 700;
  font-family: system-ui;
`;
const ApplyNowButton = styled(Button)`
  position: relative;
  padding-right: ${rem(space(600))};
`;
const ApplyNowIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 ${rem(space(300))};
  color: #4a5568;
  pointer-events: none;

  & svg {
    width: ${rem(space(400))};
    height: ${rem(space(400))};
    vertical-align: middle;
    transform: rotate(-90deg);
  }
`;

const Header = ({ siteTitle }) => (
  <Root>
    <Container>
      <TitleLink to="/" title={siteTitle} />
      <nav>
        <Menu>
          <SecondaryMenuItem>
            <MenuLink to="/">Home</MenuLink>
          </SecondaryMenuItem>
          <SecondaryMenuItem>
            <MenuLink to="/about-us">About Us</MenuLink>
          </SecondaryMenuItem>
          <SecondaryMenuItem>
            <MenuLink to="/contact">Contact</MenuLink>
          </SecondaryMenuItem>
          <MenuItem>
            <ApplyNowButton as={DefaultLink} to="/application" round>
              Apply Now
              <ApplyNowIcon>
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </ApplyNowIcon>
            </ApplyNowButton>
          </MenuItem>
        </Menu>
      </nav>
    </Container>
  </Root>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
