import styled from "styled-components";
import { rem, space } from "../design-system";

const MainContainer = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding-left: ${rem(space(400))};
  padding-right: ${rem(space(400))};
`;

export default MainContainer;
