import styled from "styled-components";
import { rem, space, elevation } from "../design-system";

const Button = styled.button`
  appearance: none;
  padding: ${rem(space(200))} ${rem(space(400))};
  border-radius: ${({ round }) => (round ? rem(space(400)) : rem(space(100)))};
  background-color: ${({ theme, fill }) => theme[fill] || "white"};
  color: ${({ theme, textColor }) => theme[textColor] || "#2d3748"};
  font-weight: 700;
  font-family: system-ui;
  text-decoration: none;
  line-height: 1.625;
  ${elevation(100)};
  cursor: pointer;

  &:hover {
    background-color: #f7fafc;
  }
`;

export default Button;
