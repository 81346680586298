import styled from "styled-components";
import { Link as DefaultLink } from "gatsby";

const Link = styled(DefaultLink)`
  color: #718096;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
`;

export default Link;
