/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled, { createGlobalStyle } from "styled-components";
import { rem, space, fontSize } from "../design-system";
import Helmet from "react-helmet";

import MainContainer from "./MainContainer";
import Header from "./header";
import Link from "./Link";
import "./layout.css";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #EDEDEF;
  }
`;
const Main = styled.main`
  padding: ${rem(space(400))};
  background-color: white;

  @media screen and (min-width: 600px) {
    padding: ${rem(space(700))};
  }
`;
const Footer = styled.footer`
  padding: ${rem(space(200))};
`;
const FooterMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  width: 100%;
  padding: ${rem(space(200))} 0;
  margin: 0;

  @media screen and (min-width: 520px) {
    display: none;
  }
`;
const FooterMenuItem = styled.li`
  font-size: ${rem(fontSize(200))};

  &:not(:first-child) {
    padding-left: ${rem(space(600))};
  }
`;
const FooterMenuLink = styled(Link)`
  font-weight: 700;
  font-family: system-ui;
`;
const Copyright = styled.div`
  text-align: center;
  font-size: ${rem(fontSize(200))};
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Helmet>
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
      </Helmet>
      <MainContainer as="main">
        <Main>{children}</Main>
        <Footer>
          <FooterMenu>
            <FooterMenuItem>
              <FooterMenuLink to="/">Home</FooterMenuLink>
            </FooterMenuItem>
            <FooterMenuItem>
              <FooterMenuLink to="/about-us">About Us</FooterMenuLink>
            </FooterMenuItem>
            <FooterMenuItem>
              <FooterMenuLink to="/contact">Contact</FooterMenuLink>
            </FooterMenuItem>
          </FooterMenu>

          <Copyright>&copy; {new Date().getFullYear()} Burch Rentals</Copyright>
        </Footer>
      </MainContainer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
